<template>
  <a-mentions
    v-model:value="text"
    :prefix="prefix"
    :style="styleProps"
    :rows="rows"
    @blur="handleBlur"
    @focus="handleFocus"
  >
    <a-mentions-option
      v-for="(option, idx) in options"
      :key="idx"
      :value="option.value"
    >
      {{ option.label }}
    </a-mentions-option>
  </a-mentions>
</template>

<script>
import { TAG_OPTIONS } from '@/config/guest-email';

export default {
  name: 'ReservationDataOptions',
  props: {
    value: {
      type: String,
      default: undefined,
    },
    prefix: {
      type: Array,
      default: () => ['#'],
    },
    styleProps: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:value', 'blur', 'focus'],
  data() {
    return {
      options: TAG_OPTIONS,
    };
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
  },
  methods: {
    handleBlur(e) {
      this.$emit('blur', e.target);
    },
    handleFocus(e) {
      this.$emit('focus', e.target);
    },
  },
};
</script>
