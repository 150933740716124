<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="template-form is-flex is-flex-direction-column is-align-items-stretch"
  >
    <div class="columns is-mini-gap is-multiline is-mobile">
      <div class="column is-12">
        <h3 class="has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h3>
      </div>

      <a-form-item class="column is-12">
        <div class="columns is-flex">
          <div class="column is-narrow">
            <p class="has-text-weight-bold">
              Status
            </p>
            <a-select
              v-model:value="form.isActive"
              style="width: 100px"
              :size="size"
            >
              <a-select-option
                v-for="(status, index) in statuses"
                :key="index"
                :value="status.id"
              >
                {{ status.name }}
              </a-select-option>
            </a-select>
          </div>
          <a-form-item class="column" name="propertyId">
            <p class="has-text-weight-bold">
              Property
            </p>
            <InputProperty v-model:value="form.propertyId" />
          </a-form-item>
        </div>
      </a-form-item>

      <a-form-item class="column is-12">
        <div class="is-flex-desktop">
          <div class="gray-wrapper is-flex-grow-1 mr-2-desktop">
            <p class="has-text-weight-bold">
              When to send?
            </p>
            <div class="columns is-mobile is-multiline">
              <div class="column is-narrow">
                <a-input-number v-model:value="form.timeValue" class="input-day" :size="size" />
              </div>
              <div class="column is-narrow">
                <p>{{ $t('days') }}</p>
              </div>
              <div class="column is-narrow">
                <a-select
                  v-model:value="form.time"
                  class="select-time"
                  :size="size"
                >
                  <a-select-option v-for="(time, index) in times" :key="index" :value="time.id">
                    {{ time.name }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="column is-narrow">
                <a-select
                  v-model:value="form.timeType"
                  class="select-time-type"
                  :size="size"
                >
                  <a-select-option
                    v-for="(category, index) in categories"
                    :key="index"
                    :value="category.id"
                    :title="category.name"
                  >
                    {{ category.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="gray-wrapper is-flex-grow-1">
            <p class="has-text-weight-bold">
              Autofill tag
            </p>
            <div class="columns">
              <div class="column is-narrow">
                <a-select
                  v-model:value="currentFillTag"
                  style="width: 180px"
                  :size="size"
                  placeholder="Select tag"
                >
                  <a-select-option
                    v-for="(tag, index) in tagOptions"
                    :key="index"
                    :value="tag.value"
                  >
                    {{ tag.label }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="column is-narrow">
                <a-button
                  :loading="loading"
                  :size="size"
                  style="min-width: 100px"
                  type="primary"
                  @click="handleAutofill"
                >
                  {{ $t('Add') }}
                </a-button>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-2">
      </a-form-item>

      <a-form-item class="column is-12" :label="$t('Subject')" name="subject">
        <InputMention
          v-model:value="form.subject"
          class="guest-email-form-subject"
          :style-props="{
            height: '38px',
            padding: '3px',
          }"
        />
      </a-form-item>
    </div>

    <div class="is-flex-grow-1">
      <a-form-item class="template-body" :label="$t('Body')" name="body">
        <InputMention
          v-model:value="form.body"
          :style-props="{
            height: '100%',
          }"
          @blur="handleBodyEvent"
          @focus="handleBodyEvent"
        />
      </a-form-item>
    </div>

    <div class="columns is-mobile">
      <div
        class="column is-narrow"
        style="min-width: 100px;"
      >
        <div v-if="value && value.id">
          <DeleteTemplate :record="value" @delete="$emit('close')">
            <template #handler="{ show }">
              <a-button
                :size="size"
                type="danger"
                @click="show"
              >
                {{ $t('Delete') }}
              </a-button>
            </template>
          </DeleteTemplate>
        </div>
      </div>
      <div class="column has-text-right">
        <a-button
          :disabled="loading"
          :size="size"
          class="button-secondary-outline action-button ml-2 m-b-2x"
          @click="resetFields"
        >
          {{ $t('Cancel') }}
        </a-button>
        <a-button
          :loading="loading"
          :size="size"
          class="ml-2 action-button"
          type="primary"
          @click="handleSubmit"
        >
          {{ $t('Save') }}
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Add Email": "Add Email",
    "Edit Email": "Edit Email",
    "Smartlock Key Code": "Smartlock Key Code",
  },
  "ja": {
    "Add Email": "Add Email",
    "Edit Email": "Edit Email",
    "Smartlock Key Code": "Smartlockキーコード",
  }
}
</i18n>

<script>
import { TAG_OPTIONS } from '@/config/guest-email';
import InputProperty from '@/components/InputProperty';
import DeleteTemplate from './DeleteTemplate';
import InputMention from './InputMention';

export default {
  name: 'EmailTemplateForm',
  components: {
    InputProperty,
    DeleteTemplate,
    InputMention,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      size: 'large',
      loading: false,
      currentFillTag: null,
      currentFillTarget: null,
      tagOptions: TAG_OPTIONS,
      form: {
        subject: undefined,
        body: undefined,
        isActive: true,
        timeValue: 0,
        time: 'priorTo',
        timeType: 'checkin',
        propertyId: undefined,
      },
      statuses: [
        { name: 'Active', id: true },
        { name: 'Inactive', id: false },
      ],
      times: [
        { name: 'Prior to', id: 'priorTo' },
        { name: 'After', id: 'after' },
      ],
      categories: [
        { name: 'Check In Date', id: 'checkin' },
        { name: 'Check Out Date', id: 'checkout' },
      ],
      rules: {
        subject: [
          {
            required: true,
            message: this.$t('Subject is required'),
            trigger: ['change', 'blur'],
          },
        ],
        body: [
          {
            required: true,
            message: this.$t('Email body is required'),
            trigger: ['change', 'blur'],
          },
        ],
        propertyId: [
          {
            required: true,
            message: this.$t('Property is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.value && this.value.id ? this.$t('Edit Email') : this.$t('Add Email');
    },
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          const obj = Object.keys(this.form);
          obj.forEach((key) => {
            this.form[key] = nv[key];
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    if (this.$route.query.propertyId && !this.form.propertyId) {
      this.form.propertyId = this.$route.query.propertyId;
    }
  },
  methods: {
    handleBodyEvent(el) {
      this.currentFillTarget = el;
    },
    handleAutofill() {
      const el = this.currentFillTarget;

      if (!el || !this.currentFillTag) {
        return;
      }

      el.focus();

      const body = this.form.body || '';
      const tag = `#${this.currentFillTag} `;

      let cursorPos = el.selectionStart;
      this.form.body = body.substring(0, cursorPos) + tag + body.substring(cursorPos);

      cursorPos += tag.length; // new cursor position

      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        if (this.value && this.value.id) {
          await this.$store.dispatch('guest-email/updateTemplate', {
            ...this.form,
            id: this.value.id,
          });
        } else {
          await this.$store.dispatch('guest-email/createTemplate', this.form);
        }

        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.template-form {
    height: 100%;
  }

.template-body {
  height: 100%;
  margin-bottom: 0;

  .ant-form-item-control-wrapper,
  .ant-form-item-control,
  textarea {
    height: 100%;
  }
}

@media (min-width: 800px) {
  .template-body {
    padding-bottom: 72px;
  }
}

.guest-email-form-subject textarea {
  white-space: nowrap;
}

.gray-wrapper {
  background-color: #F5F5F5;
  padding: 0 .5rem .5rem;
}

.mr-2-desktop {
  margin-bottom: 0.5rem;
}
@include desktop {
  .mr-2-desktop {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
}
.input-day {
  width: 50px!important;
}
.select-time {
  width: 80px!important;
}
.select-time-type {
  width: 100px!important;
}
.action-button {
  min-width: 95px;
}
@include tablet {
  .action-button {
    min-width: 115px;
  }
  .input-day {
    width: 88px!important;
  }
  .select-time {
    width: 100px!important;
  }
  .select-time-type {
    width: 150px!important;
  }
}

</style>
