<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-select
      v-model:value="form.id"
      show-search
      :placeholder="$t('Enter reservation number')"
      style="width: 100%"
      :default-active-first-option="false"
      :filter-option="false"
      :not-found-content="null"
      size="large"
      @search="delaySearch"
      @change="handleChange"
    >
      <a-select-option v-for="d in data" :key="d.id">
        {{ d.basicInformation.travelAgencyBookingNumber }}
      </a-select-option>
    </a-select>
    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        size="large"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Cancel Reservation') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Enter reservation number": "Enter reservation number",
    "Cancel Reservation": "Cancel Reservation"
  },
  "ja": {
    "Enter reservation number": "予約番号を入力してください",
    "Cancel Reservation": "予約のキャンセル",
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'NeppanCancelReservationForm',
  emits: ['submit'],
  data() {
    return {
      data: [],
      rules: {},
      form: {
        id: undefined,
      },
    };
  },
  methods: {
    handleSearch(value = '') {
      this.$store
        .dispatch('reservations/getNeppan', { travelAgencyBookingNumber: value })
        .then((resp) => {
          this.data = resp;
        });
    },
    delaySearch: debounce(function (value) {
      this.handleSearch(value);
    }, 600),
    handleSubmit() {
      this.$emit('submit', this.form.id);
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style></style>
