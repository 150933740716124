<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" neppan-only @change="handlePropertyChange" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('room name')" name="roomId">
      <InputRoom v-model:value="form.roomId" :property-id="form.propertyId" return-value="code" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check in - check out')" name="checkInOutDate">
      <a-range-picker
        v-model:value="form.checkInOutDate"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
      >
        <a-input :value="rangeString" :placeholder="$t('date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </a-form-item>
    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "property": "Property",
    "room name": "Room name",
    "save": "Save",
    "Check in - check out": "Check in - check out",
    "date range": "Date range",
  },
  "ja": {
    "property": "物件",
    "room name": "部屋番号",
    "save": "保存",
    "Check in - check out": "チェックイン時刻 - チェックアウト時刻",
    "date range": "日付範囲",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';

export default {
  name: 'NeppanReservationForm',
  components: {
    CalendarFilled,
    InputProperty,
    InputRoom,
  },
  emits: ['submit'],
  data() {
    return {
      size: 'large',
      dateFormat: 'YYYY-MM-DD',
      form: {
        propertyId: undefined,
        roomId: undefined,
        checkInOutDate: [],
      },
      rules: {},
    };
  },
  computed: {
    rangeString() {
      return this.form.checkInOutDate.length
        ? `${this.$filters.date(this.form.checkInOutDate[0], {
          format: this.dateFormat,
        })} ~ ${this.$filters.date(this.form.checkInOutDate[1], { format: this.dateFormat })}`
        : '';
    },
    formattedRange() {
      return this.form.checkInOutDate.length
        ? `${this.$filters.date(this.form.checkInOutDate[0], {
          format: this.dateFormat,
        })},${this.$filters.date(this.form.checkInOutDate[1], { format: this.dateFormat })}`
        : undefined;
    },
  },
  methods: {
    async handleSubmit() {
      await this.$refs.form.validate();
      const checkInDate = this.$filters.date(this.form.checkInOutDate[0], {
        format: this.dateFormat,
      });
      const checkOutDate = this.$filters.date(this.form.checkInOutDate[1], {
        format: this.dateFormat,
      });
      const payload = {
        basicInformation: {
          travelAgencyBookingNumber: `${this.$filters.date(new Date(), {
            format: 'YYYYMMDDHHmmss',
          })}`,
          travelAgencyBookingDate: this.$filters.date(new Date(), { format: this.dateFormat }),
          travelAgencyBookingTime: this.$filters.date(new Date(), { format: 'HH:mm:ss' }),
          checkInDate,
          checkOutDate,
          checkInTime: '12:00:00',
          nights: dayjs(checkOutDate).diff(checkInDate, 'd'),
        },
        accommodationInformation: {
          accommodationCode: this.form.propertyId,
          accommodationName: '',
        },
        roomInformation: {
          roomTypeCode: this.form.roomId,
        },
        roomRateInformation: {
          roomDate: checkInDate,
        },
        salesOfficeInformation: {
          salesOfficeCompanyCode: 'TEST',
        },
      };
      this.$emit('submit', payload);
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style></style>
