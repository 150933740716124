<template>
  <div class="page">
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <a-button
              class="ant-btn ant-btn-lg ant-btn-primary"
              @click="handleOpenTemplateModal"
            >
              {{ $t('Add Email') }}
            </a-button>
          </div>
        </div>

        <Modal
          ref="emailTemplate"
          width="800px"
          is-fit
        >
          <template #default="{ hide }">
            <EmailTemplateForm
              :value="email"
              @cancel="() => {
                hide();
                email = undefined;
              }"
              @close="() => {
                fetchData();
                hide();
              }"
            />
          </template>
        </Modal>

        <a-table
          :columns="columns"
          :data-source="emails"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          row-key="id"
          @change="handleTableChange"
        >
          <template #timeValue="{ record }">
            <span
              v-if="record.timeValue > 0"
            >
              {{ record.timeValue }}d {{ times[record.time] }}
            </span>
            <span
              v-else
            >
              {{ record.timeValue }}
            </span>
          </template>
          <template #subject="{ record }">
            {{ record.subject }}
          </template>
          <template #isActive="{ record }">
            {{ record.isActive ? $t('Active') : $t('Inactive') }}
          </template>
          <template #updatedAt="{ record }">
            {{ $filters.date(record.updatedAt, { format: $variables.datetimeFormat }) }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Email templates": "Email templates",
    "Add Email": "Add Email"
  }
}
</i18n>

<script>
import pagination from '@/components/mixins/pagination';
import Modal from '@/components/ModalWide';

import EmailTemplateForm from './components/EmailTemplateForm';
import SearchForm from './components/SearchForm';

export default {
  name: 'EmailTemplates',
  components: {
    SearchForm,
    Modal,
    EmailTemplateForm,
  },
  mixins: [pagination],
  data() {
    return {
      isFetching: false,
      emails: undefined,
      email: undefined,
      sender: {
        email: undefined,
        name: undefined,
      },
      columns: [
        {
          title: this.$t('When to send'),
          dataIndex: 'timeValue',
          key: 'timeValue',
          slots: { customRender: 'timeValue' },
        },
        {
          title: this.$t('Email Subject'),
          dataIndex: 'subject',
          key: 'subject',
          width: 560,
          slots: { customRender: 'subject' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'isActive',
          key: 'isActive',
          slots: { customRender: 'isActive' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Updated At'),
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          slots: { customRender: 'updatedAt' },
        },
      ],
      times: {
        priorTo: this.$t('prior'),
        after: this.$t('after'),
      },
    };
  },
  mounted() {
    this.fetchData();

    window.addEventListener('resize', () => {
      if (window.innerWidth < 500) {
        this.columns[1].width = 300;
      } else {
        this.columns[1].width = 560;
      }
    });
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.email = undefined;

      this.$store
        .dispatch('guest-email/listTemplate', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;
          this.emails = list;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleOpenTemplateModal() {
      this.$refs.emailTemplate.show();
    },
    rowClicked(record) {
      return {
        onClick: async () => {
          const templateData = await this.$store.dispatch('guest-email/showTemplate', record.id);
          this.email = templateData;
          this.$refs.emailTemplate.show();
        },
      };
    },
  },
};
</script>
