<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-2">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        style="width: 100%"
        displayed-text="abbreviation"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-2">
      <a-button
        v-show="propertyId"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Clear": "Clear"
  },
  "ja": {
    "Clear": "クリア"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';

export default {
  name: 'SearchForm',
  components: { InputProperty },
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
    };
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
  },
  methods: {
    debounceQuery: debounce(async function debounceQuery() {
      const querySearch = {};
      const sort = {};

      if (this.propertyId) {
        querySearch.propertyId = this.propertyId;
      }

      await this.$router.push({
        query: {
          page: 1,
          ...querySearch,
          ...sort,
        },
      });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.debounceQuery();
    },
  },
};
</script>

<style scoped></style>
