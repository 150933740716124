export const EMAIL_EVENT_STATUS = {
  SENT: 'sent',
  IN_PROGRESS: 'in-progress',
  FAILED: 'failed',
};

export const TAG_OPTIONS = [
  { label: 'Reservation number', value: '{reservationNumber}' },
  { label: 'OTA reservation number', value: '{otaReservationNumber}' },
  { label: 'Property name', value: '{propertyName}' },
  { label: 'Room name', value: '{roomName}' },
  { label: 'Room number', value: '{roomNumber}' },
  { label: 'Check-in date', value: '{checkin}' },
  { label: 'Check-out date', value: '{checkout}' },
  { label: 'Guest name', value: '{guestName}' },
  { label: 'Total number of pax', value: '{totalPax}' },
  { label: 'Email', value: '{email}' },
  { label: 'Smartlock Key Code', value: '{smartlockKeyCode}' },
  { label: 'Guest Registration Link', value: '{guestPreEntryUrl}' },
  { label: 'OTA reservation number or Travel agency number', value: '{otaNumberOrTravelAgencyNumber}' },
];
