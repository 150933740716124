<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Neppan Reservation Simulator') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <h3 class="is-5 title">
          {{ $t('Create Reservation') }}
        </h3>
        <NeppanReservationForm ref="form" :is-submitting="isSubmitting" @submit="handleSubmit" />
        <a-alert v-if="ok" type="success">
          <template #message> {{ $t('Neppan reservation has been added') }}. </template>
        </a-alert>
      </div>
      <div class="cancellation-form container is-mobi mt-3">
        <h3 class="is-5 title">
          {{ $t('Cancel Reservation') }}
        </h3>
        <NeppanCancelReservationForm
          ref="cancellationForm"
          :is-submitting="isSubmitting"
          @submit="handleCancel"
        />
        <a-alert v-if="cancelOk" type="success">
          <template #message> {{ $t('Neppan reservation has been cancelled') }}. </template>
        </a-alert>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Neppan Reservation Simulator": "Neppan Reservation Simulator",
    "Create Reservation": "Create Reservation",
    "Neppan reservation has been added": "Neppan reservation has been added",
    "Cancel Reservation": "Cancel Reservation",
    "Neppan reservation has been cancelled": "Neppan reservation has been cancelled"
  },
  "ja": {
    "Neppan Reservation Simulator": "ネパン予約シミュレーター",
    "Create Reservation": "予約を作成する",
    "Neppan reservation has been added": "ネパン予約を追加しました",
    "Cancel Reservation": "予約のキャンセル",
    "Neppan reservation has been cancelled": "ネパンの予約はキャンセルされました"
  }
}
</i18n>

<script>
import NeppanReservationForm from './NeppanReservationForm';
import NeppanCancelReservationForm from './NeppanCancelReservationForm';

export default {
  name: 'NeppanReservation',
  components: {
    NeppanReservationForm,
    NeppanCancelReservationForm,
  },
  data() {
    return {
      isSubmitting: false,
      ok: false,
      cancelOk: false,
    };
  },
  methods: {
    handleSubmit(data) {
      this.isSubmitting = true;
      this.$store
        .dispatch('reservations/addNeppan', data)
        .then((res) => {
          this.ok = res.ok;
          this.$refs.form.resetFields();
        })
        .finally(() => {
          this.isSubmitting = false;
          setTimeout(() => {
            this.ok = false;
          }, 1e3);
        });
    },
    handleCancel(id) {
      this.$store
        .dispatch('reservations/cancelNeppan', { id })
        .then(() => {
          this.cancelOk = true;
          this.$refs.cancellationForm.resetFields();
        })
        .finally(() => {
          setTimeout(() => {
            this.cancelOk = false;
          }, 1e3);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cancellation-form {
  border-top: 1px solid #d3d3d3;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
